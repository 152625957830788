import React, {useState, useEffect} from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import get from 'lodash/get';

import {StaticImage} from 'gatsby-plugin-image';

import Master from '../templates/Master.jsx';
import SEO from '../components/shared/seo';
import Hero from '../components/shared/Hero';
import SearchButton from '../components/shared/SearchButton.jsx';

const Container = styled.section`
	/* padding: 3rem 1rem; */
`;
const SearchContainer = styled.section`
	background-color: ${({theme}) => theme.colors.primary};
	padding: 1rem;
	> div {
		${({theme}) => theme.container}
	}
`;
const SearchSection = styled.section`
	display: flex;
	padding: 6rem 0;
	background: #252839;
`;

// const isMobile = window.innerWidth <= 640;

const Index = ({data: {kontentItemPage: data}}) => {
	const [width, setWidth] = useState('');

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	const props = {
		hero: get(data, 'elements.hero.value'),
		content: get(data, 'elements.content.value'),
		links: get(data, 'elements.content.links'),
		images: get(data, 'elements.content.images'),
	};
	return (
		<Master>
			<SEO title="Home" />
			{(() => {
				if (width <= 1024) {
					return (
						<section>
							<SearchButton />
						</section>
					);
				}
			})()}
			<Hero linkedItem={props.hero} page={'home'} />
			<StaticImage src="../images/Banner_websitev2.jpg" />
		</Master>
	);
};

export default Index;

export const query = graphql`
	query {
		kontentItemPage(elements: {title: {value: {eq: "Home"}}}) {
			elements {
				title {
					value
				}
				content {
					links {
						codename
						link_id
						type
						url_slug
					}
					images {
						image_id
						url
						description
					}
					value
				}
				hero {
					value {
						...kontentItemSectionHeroFragment
					}
				}
			}
		}
	}
`;
